import React, { useEffect, useRef, useState } from "react";
import style from '../SCSS/pages/Main.module.scss';
import LeftBar from "../components/LeftBar";
import { Link } from "react-router-dom";
import axios from "axios";

const Main = () => {

    const isLoggedIn = () => {
        const loggedInUser = sessionStorage.getItem('member_id');
        return loggedInUser !== null;
    };

    const handleLinkClick = e => {
        if (!isLoggedIn()) {
            alert('로그인이 필요한 서비스입니다.');
            e.preventDefault();
        }
    };

    ////////////////////////////////////////

    const processWrapperRef = useRef(null);
    let isDragging = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
        isDragging = true;
        startX = e.pageX - processWrapperRef.current.offsetLeft;
        scrollLeft = processWrapperRef.current.scrollLeft;
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - processWrapperRef.current.offsetLeft;
        const walk = x - startX;
        processWrapperRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        isDragging = false;
    };

    useEffect(() => {
        const processWrapper = processWrapperRef.current;
        processWrapper.addEventListener('mousedown', handleMouseDown);
        processWrapper.addEventListener('mousemove', handleMouseMove);
        processWrapper.addEventListener('mouseup', handleMouseUp);
        processWrapper.addEventListener('mouseleave', handleMouseUp);

        return () => {
            processWrapper.removeEventListener('mousedown', handleMouseDown);
            processWrapper.removeEventListener('mousemove', handleMouseMove);
            processWrapper.removeEventListener('mouseup', handleMouseUp);
            processWrapper.removeEventListener('mouseleave', handleMouseUp);
        };
    }, []);


    return (
        <div className={style.Main}>

            <LeftBar />
            <div className={style.right_wrapper}>
                {/* <TopBanner /> */}
                <div className={style.ex_content_wrapper}>
                    <div className={style.ex_content_box}>

                        <div className={style.process_wrapper} ref={processWrapperRef}>
                            <div className={style.process_box}>
                                <h2>
                                    입력
                                </h2>
                                <div className={style.svg_box}>
                                    <img src='/process_1.svg' alt='Process 1' />
                                </div>
                                <div className={style.process_explain_wrapper}>
                                    <h3>
                                        Step.1
                                    </h3>
                                    <p>
                                        자기소개서를
                                    </p>
                                    <p>
                                        입력합니다.
                                    </p>
                                </div>
                            </div>
                            <div className={style.process_box}>
                                <h2>
                                    평가
                                </h2>
                                <div className={style.svg_box}>
                                    <img src='/process_2.svg' alt='Process 2' />
                                </div>
                                <div className={style.process_explain_wrapper}>
                                    <h3>
                                        Step.2
                                    </h3>
                                    <p>
                                        합격 자기소개서와
                                    </p>
                                    <p>
                                        유사도를
                                    </p>
                                    <p>
                                        판별합니다.
                                    </p>
                                </div>
                            </div>
                            <div className={style.process_box}>
                                <h2>
                                    분석
                                </h2>
                                <div className={style.svg_box}>
                                    <img src='/process_3.svg' alt='Process 3' />
                                </div>
                                <div className={style.process_explain_wrapper}>
                                    <h3>
                                        Step.3
                                    </h3>
                                    <p>
                                        문제점을 찾습니다
                                    </p>
                                </div>
                            </div>
                            <div className={style.process_box}>
                                <h2>
                                    첨삭
                                </h2>
                                <div className={style.svg_box}>
                                    <img src='/process_4.svg' alt='Process 4' />
                                </div>
                                <div className={style.process_explain_wrapper}>
                                    <h3>
                                        Step.4
                                    </h3>
                                    <p>
                                        자기소개서를
                                    </p>
                                    <p>
                                        첨삭합니다.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={style.input_wrapper}>
                            <Link to='/essayinput' onClick={handleLinkClick} className={style.ai_start_btn_01}>
                                {/* <img src='/aistart.png' alt='start' /> */}
                                <img src="/test_01.svg" alt="start" />
                                <img src="/test_02.svg" alt="start" />
                            </Link>
                            <Link to='/talentinput' onClick={handleLinkClick}>
                                <img src='/aitalent.svg' alt='talent' />
                            </Link>
                        </div>

                    </div>

                </div>
                <div className={style.reserved_box}>
                    <p>
                        Copyright ⓒ 2024 persona ai All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Main;
