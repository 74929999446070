import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import style from '../SCSS/components/TopBanner.module.scss'
import { Link } from 'react-router-dom';

const TopBanner = ({ handleToggleDarkMode }) => {

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [memberCoin, setMemberCoin] = useState(0);

    const getMemberCoin = async (e) => {
        const member_id = sessionStorage.getItem('member_id');

        try {
            const res = await axios.post(`${baseUrl}/member/coin`, {
                member_id
            })
            setMemberCoin(res.data.coin);
        } catch (error) {
            console.error(error.response.data)
        }
    }



    const [logoutModal, setLogoutModal] = useState(false);
    const logoutModalRef = useRef(null);

    const handdleModal = () => {
        setLogoutModal(!logoutModal);
    }

    const handdleLogout = () => {
        const confirmed = window.confirm("로그아웃 하시겠습니까?");
        if (!confirmed) return;
        sessionStorage.clear();
        window.location.reload();
    }


    useEffect(() => {
        if (sessionStorage.getItem('member_id')) {
            getMemberCoin();
        }
    }, [])


    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const theme = localStorage.getItem('theme');
        if (theme === 'dark') {
            setIsDarkMode(true);
        }
    }, []);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (logoutModalRef.current && !logoutModalRef.current.contains(event.target)) {
                setLogoutModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [logoutModalRef]);


    return (
        <>
            <div className={style.login_wrapper}>
                <div className={style.dark_btn}>
                    {/* {theme === 'dark' ? (
                            <img src='/lightmode.svg' />
                        ) : (
                            <img src='/darkmode.svg' />
                        )
                        } */}
                    <label className={style.ui_switch}>
                        <input
                            type="checkbox"
                            onClick={handleToggleDarkMode}
                            checked={isDarkMode}
                            onChange={() => setIsDarkMode(!isDarkMode)}
                        />
                        <div className={style.slider}>
                            <div className={style.circle}></div>
                        </div>
                    </label>
                </div>
                {sessionStorage.getItem('member_id') ?
                    <>
                        <div className={style.coin_box}>
                            <img src='/coin.svg' alt='coin' />
                            <p>
                                {memberCoin}
                            </p>
                        </div>
                        <div className={style.login_id_box} onClick={handdleModal}>
                            {/* <h2>
                            {sessionStorage.getItem('member_id')}
                        </h2> */}
                            <img src='/profile_pic.svg' alt='profile' />
                        </div>
                        {logoutModal &&
                            <div className={style.logout_box} onClick={handdleLogout} ref={logoutModalRef}>
                                <p>로그아웃</p>
                            </div>
                        }
                    </>
                    :
                    <>
                        <div className={style.login_box}>
                            <Link to='/login'>
                                <h2>
                                    LOGIN
                                </h2>
                            </Link>
                        </div>
                        <div className={style.join_box}>
                            <Link to='/join'>
                                <h2>
                                    JOIN
                                </h2>
                            </Link>
                        </div>
                    </>
                }
            </div>

        </>
    )
}

export default TopBanner