import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: window.localStorage.getItem('theme') || 'light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      window.localStorage.setItem('theme', newTheme);
      document.body.setAttribute('data-theme', newTheme);
      state.theme = newTheme;
    },
  },
});

export const { toggleDarkMode } = themeSlice.actions;
export default themeSlice.reducer;