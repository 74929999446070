import React, { useEffect } from 'react'

const GoogleLogin = () => {

    useEffect(() => {
        
        const PARAMS = new URL(document.location).searchParams;
        const member_id = PARAMS.get("member_id");
        sessionStorage.setItem('member_id', member_id);
        window.location.href = '/';
    }, [])
  




  return (
    <div></div>
  )
}

export default GoogleLogin