import React, { useEffect, useState } from 'react'
import style from '../SCSS/pages/EssayFeedback.module.scss'
import LeftBar from '../components/LeftBar'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { diffChars } from 'diff';
import { useSelector } from 'react-redux';

const EssayFeedback = () => {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { _id } = useParams(); // id 값 가져오기

  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  }

  const [orionResponses, setOrionResponses] = useState([]);

  const getOrionAnswer = async () => {
    try {
      const response = await axios.post(`${baseUrl}/essay_history/getAnswer`, {
        id: _id
      });
      setOrionResponses(response.data.gpt_answer);
    } catch (error) {
      console.error("Error getting data:", error);
    }
  }

  useEffect(() => {
    getOrionAnswer();
  }, [_id]);

  const [highlightedText, setHighlightedText] = useState('');

  const highlightDiff = (text1, text2) => {
    const diff = diffChars(text1, text2);
    let highlightedText = '';

    diff.forEach((part) => {
      if (part.added) {
        // const value = part.value.replace(/\n/g, ''); // 개행 문자 제거
        // highlightedText += `<span style="color:red;">${part.value}</span>`;
        highlightedText += `<span className="higlight_span">${part.value}</span>`;
      } else if (!part.removed) {
        highlightedText += part.value;
      }
    });

    return highlightedText;
  };

  const formatEvaluationText = (text) => {
    // [평가]를 제거하고, 특정 키워드 강조하기
    text = text.replace(/\[평가\]\n/g, '');
    text = text.replace(/이런 점이 좋아요:\n/g, '<h3 style="color:#6262ff; background:#fbfff5;">이런 점이 좋아요:</h3>');
    text = text.replace(/다시 생각해 봐요:\n/g, '<h3 style="color:#ef3f3f; background:#fbfff5;">다시 생각해 봐요:</h3>');
    return text;
  };

  const AnswerComponent = ({ props }) => {
    // const score = props.orion_text.match(/\[평가\]:\s?(\d+점)/)[1]
    // const reason = props.orion_text.match(/\[평가 이유\]:\s?(.+)/)[1];
    // const orion_answer = props.orion_text.match(/\[수정\]:\s?([\s\S]+)/)[1];

    highlightDiff(props.user_text, props.orion_text)

    setHighlightedText(highlightDiff(props.user_text, props.orion_text).replace(/\[첨삭\]\n\n/g, ''));

    return (
      <div className={style.gpt_answer_box}>
        <div className={style.gpt_evaluate}>
          <div className={style.gpt_evaluate_title}>
            <h2>1. 평가</h2>
            {/* <p>0점 ~ 10점</p> */}
          </div>
          <div className={style.gpt_evaluate_score}>
            {/* <h3>Score</h3>
            <div className={style.score_box}>
              <img src="/ai_robot.svg" alt="AI Robot" />
              <div className={style.content_box}>
                <p>{score}</p>
              </div>

            </div> */}
            <h3>Why.</h3>
            <div className={style.why_box}>
              {isDark ?
                <img src="/ai_robot_white.svg" alt="AI Robot" />
                :
                <img src="/ai_robot.svg" alt="AI Robot" />
              }
              <div className={style.content_box}>
                <p dangerouslySetInnerHTML={{ __html: formatEvaluationText(props.orion_eval).replace(/\n/g, '<br/>') }} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.gpt_editing}>
          <div className={style.gpt_editing_title}>
            <h2>2. 첨삭</h2>
          </div>

          <div className={style.gpt_editing_content}>
            <h3>
              원본
            </h3>
            <div className={style.not_edited_box}>
              <div className={style.content_box}>
                <p dangerouslySetInnerHTML={{ __html: props.user_text.replace(/\n/g, '<br/>') }} />
              </div>
            </div>
            <h3>
              수정본
            </h3>
            <div className={style.edited_box}>
              {isDark ?
                <img src="/ai_robot_white.svg" alt="AI Robot" />
                :
                <img src="/ai_robot.svg" alt="AI Robot" />
              }
              <div className={style.edited_content}>
                {/* <p dangerouslySetInnerHTML={{ __html: props.orion_text.replace(/\n/g, '<br/>') }} /> */}
                <p dangerouslySetInnerHTML={{ __html: highlightedText.replace(/\n/g, '<br/>') }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const [isDark, setIsDark] = useState(window.localStorage.getItem('theme'));
  const theme = useSelector((state) => state.theme.theme);
  useEffect(() => {
    if (theme == 'light') {
      setIsDark(false);
    } else {
      setIsDark(true);
    }
  }, [theme])


  return (
    <div className={style.Main}>
      <LeftBar />
      <div className={style.right_wrapper}>
        <div className={style.ex_content_wrapper}>
          <div className={style.ex_content_box}>
            <div className={style.gpt_answer_wrapper}>
              <div className={style.category_btn_wrapper_wrapper}>
                <div className={style.category_btn_wrapper}>
                  <div
                    className={`${style.category_btn_box} ${selectedCategory === 0 ? style.category_btn_selected : ''}`}
                    onClick={() => handleCategoryClick(0)}
                  >
                    성장과정
                  </div>
                  <div
                    className={`${style.category_btn_box} ${selectedCategory === 1 ? style.category_btn_selected : ''}`}
                    onClick={() => handleCategoryClick(1)}
                  >
                    성격의 장단점
                  </div>
                  <div
                    className={`${style.category_btn_box} ${selectedCategory === 2 ? style.category_btn_selected : ''}`}
                    onClick={() => handleCategoryClick(2)}
                  >
                    지원동기
                  </div>
                  <div
                    className={`${style.category_btn_box} ${selectedCategory === 3 ? style.category_btn_selected : ''}`}
                    onClick={() => handleCategoryClick(3)}
                  >
                    입사 후 포부
                  </div>
                </div>
                {/* <hr /> */}
              </div>
              {
                orionResponses.length === 0
                  ?
                  <div>로딩중...</div>
                  :
                  <AnswerComponent props={orionResponses[selectedCategory]} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EssayFeedback
