import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    history_first: [],
    history_talent: []
};

const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        getHistoryFirst: (state, action) => {
            state.history_first = action.payload
        },
        getHistoryTalent: (state, action) => {
            state.history_talent = action.payload
        },
    },
});



export const { getHistoryFirst, getHistoryTalent } = historySlice.actions;
export default historySlice.reducer;