import React, { useEffect, useState } from 'react'
import style from '../SCSS/pages/EssayInput.module.scss'
import LeftBar from '../components/LeftBar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


const EssayInput = () => {

    const modelUrl = process.env.REACT_APP_MODEL_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();

    // 네가지 textarea 값들의 상태 정의
    const [growthProcess, setGrowthProcess] = useState('');
    const [strengthsAndWeaknesses, setStrengthsAndWeaknesses] = useState('');
    const [motivation, setMotivation] = useState('');
    const [aspiration, setAspiration] = useState('');
    const [responsesCount, setResponsesCount] = useState(0); // 받은 응답 수

    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        // 받은 응답 수가 증가할 때마다 체크하여 처리
        if (responsesCount === 8) {
            saveHistory();
        }

    }, [responsesCount, navigate]);


    const [obj, setObj] = useState([]);


    const handleSubmit = async () => {
        // 각 텍스트 영역의 길이를 가져옵니다.
        const growthProcessLength = growthProcess.length;
        const strengthsAndWeaknessesLength = strengthsAndWeaknesses.length;
        const motivationLength = motivation.length;
        const aspirationLength = aspiration.length;

        // 글자 수가 300 미만이거나 1000 초과인 경우 경고 메시지를 표시하고 제출을 막습니다.
        if (
            growthProcessLength < 300 || growthProcessLength > 1000 ||
            strengthsAndWeaknessesLength < 300 || strengthsAndWeaknessesLength > 1000 ||
            motivationLength < 300 || motivationLength > 1000 ||
            aspirationLength < 300 || aspirationLength > 1000
        ) {
            window.alert("각 항목의 글자 수는 300자 이상 1000자 이하여야 합니다.");
            return;
        }

        const confirmed = window.confirm("정말 제출하시겠습니까?");
        if (confirmed) {

            setIsModalOpen(true);
            try {
                // const growthProcessEval = await evalItem('성장과정', growthProcess);
                // const growthProcessResponse = await submitItem('성장과정', growthProcess);
                // const strengthsAndWeaknessesEval = await evalItem('성격의 장단점', strengthsAndWeaknesses);
                // const strengthsAndWeaknessesResponse = await submitItem('성격의 장단점', strengthsAndWeaknesses);
                // const motivationEval = await evalItem('지원동기', motivation);
                // const motivationResponse = await submitItem('지원동기', motivation);
                // const aspirationEval = await evalItem('입사 후 포부', aspiration);
                // const aspirationResponse = await submitItem('입사 후 포부', aspiration);
                const growthProcessEval = await evalItem(generateRandomItemName(), growthProcess);
                const growthProcessResponse = await submitItem(generateRandomItemName(), growthProcess);
                const strengthsAndWeaknessesEval = await evalItem(generateRandomItemName(), strengthsAndWeaknesses);
                const strengthsAndWeaknessesResponse = await submitItem(generateRandomItemName(), strengthsAndWeaknesses);
                const motivationEval = await evalItem(generateRandomItemName(), motivation);
                const motivationResponse = await submitItem(generateRandomItemName(), motivation);
                const aspirationEval = await evalItem(generateRandomItemName(), aspiration);
                const aspirationResponse = await submitItem(generateRandomItemName(), aspiration);

                setObj([
                    {
                        item: '성장과정',
                        user_text: growthProcess,
                        orion_eval: growthProcessEval,
                        orion_text: growthProcessResponse,
                    },
                    {
                        item: '성격의 장단점',
                        user_text: strengthsAndWeaknesses,
                        orion_eval: strengthsAndWeaknessesEval,
                        orion_text: strengthsAndWeaknessesResponse,
                    },
                    {
                        item: '지원동기',
                        user_text: motivation,
                        orion_eval: motivationEval,
                        orion_text: motivationResponse,
                    },
                    {
                        item: '입사 후 포부',
                        user_text: aspiration,
                        orion_eval: aspirationEval,
                        orion_text: aspirationResponse,
                    }
                ]);

            } catch (error) {
                console.error("Error submitting data:", error);
            }
        }
    };


    const [orionResponses, setOrionResponses] = useState([]);

    const orionEdit = async (itemName, itemValue) => {

        try {
            const response = await axios.post(`${modelUrl}/orion_edit`, {
                text: itemValue,
                item: itemName
            });
            const obj = {
                item: itemName,
                user_text: itemValue,
                orion_text: response.data,
            }
            setOrionResponses(prev => [...prev, obj]);
            setResponsesCount(prevCount => prevCount + 1);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const evalItem = async (itemName, itemValue) => {
        const response = await axios.post(`${modelUrl}/eval`, {
            text: itemValue,
            item: itemName
        });
        setResponsesCount(prevCount => prevCount + 1);
        return response.data;
    }

    const submitItem = async (itemName, itemValue) => {
        const response = await axios.post(`${modelUrl}/editing`, {
            text: itemValue,
            item: itemName
        });
        setResponsesCount(prevCount => prevCount + 1); // 받은 응답 수 증가
        return response.data;
    };


    const saveHistory = async () => {
        try {
            const response = await axios.post(`${baseUrl}/essay_history/save`, {
                member_id: sessionStorage.getItem('member_id'),
                gpt_answer: obj
            });

            sessionStorage.setItem('essay_history_id', response.data);
            window.location.href = `/essayfeedback/${response.data}`;


        } catch (error) {
        }
    }


    // 난수생성
    const generateRandomItemName = () => {
        return Math.random().toString(36).substring(2, 15); // 랜덤한 문자열 생성
    }

    // 클래스명 동적 할당 함수
    const getCharCountClassName = (length) => {
        return length < 300 || length > 1000 ? style.warning : '';
    }

    return (
        <div className={style.Main}>
            <LeftBar />
            <div className={style.right_wrapper}>
                <div className={style.ex_content_wrapper}>
                    <div className={style.ex_content_box}>
                        <div className={style.job_input_wrapper}>
                            <div className={style.job_input_box}>
                                <h2>
                                    1. 성장과정
                                </h2>
                                <div className={style.text_wrapper}>
                                    <textarea placeholder='성장과정을 입력해주세요.' value={growthProcess} onChange={(e) => setGrowthProcess(e.target.value)}></textarea>
                                </div>
                                <div className={`${style.charCount} ${getCharCountClassName(growthProcess.length)}`}>
                                    {growthProcess.length}/1000
                                </div>
                            </div>
                            <div className={style.job_input_box}>
                                <h2>
                                    2. 성격의 장단점
                                </h2>
                                <div className={style.text_wrapper}>
                                    <textarea placeholder='성격의 장단점을 입력해주세요.' value={strengthsAndWeaknesses} onChange={(e) => setStrengthsAndWeaknesses(e.target.value)}></textarea>
                                </div>
                                <div className={`${style.charCount} ${getCharCountClassName(strengthsAndWeaknesses.length)}`}>
                                    {strengthsAndWeaknesses.length}/1000
                                </div>
                            </div>
                            <div className={style.job_input_box}>
                                <h2>
                                    3. 지원동기
                                </h2>
                                <div className={style.text_wrapper}>
                                    <textarea placeholder='지원동기를 입력해주세요.' value={motivation} onChange={(e) => setMotivation(e.target.value)}></textarea>
                                </div>
                                <div className={`${style.charCount} ${getCharCountClassName(motivation.length)}`}>
                                    {motivation.length}/1000
                                </div>
                            </div>
                            <div className={style.job_input_box}>
                                <h2>
                                    4. 입사 후 포부
                                </h2>
                                <div className={style.text_wrapper}>
                                    <textarea placeholder='입사 후 포부를 입력해주세요.' value={aspiration} onChange={(e) => setAspiration(e.target.value)}></textarea>
                                </div>
                                <div className={`${style.charCount} ${getCharCountClassName(aspiration.length)}`}>
                                    {aspiration.length}/1000
                                </div>
                            </div>
                        </div>
                        <div className={style.submit_btn_wrapper}>
                            <div className={style.submit_btn_box} onClick={handleSubmit}>
                                제출하기
                            </div>
                        </div>
                        {isModalOpen &&
                            <div className={style.response_modal}>
                                <div className={style.response_modal_box}>
                                    {/* <div className={style.loading_wrapper}>
                                        <div className={style.loading_box}></div>
                                    </div> */}
                                    <video autoPlay loop muted>
                                        <source src="/sona_circle_1600_bg.mp4" type="video/mp4" />
                                    </video>
                                    {/* <h2>
                                        자기소개서를 분석중입니다.
                                    </h2> */}
                                    <p>{(responsesCount / 8) * 100}%</p>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EssayInput