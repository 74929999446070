import React, { useEffect } from 'react'
import axios from 'axios'

const KakaoLogin = () => {

    
    useEffect(() => {
        
        const PARAMS = new URL(document.location).searchParams;
        const member_id = PARAMS.get("member_id");
        sessionStorage.setItem('member_id', member_id);
        window.location.href = '/';
    }, [])
  


  return (
    <div></div>
  )
}

export default KakaoLogin