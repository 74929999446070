import React, { useState } from 'react'
import style from '../SCSS/pages/Join.module.scss'
import axios from 'axios';

const Join = () => {

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [member_id, setMember_id] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);

    const handdleSubmit = async (e) => {
        e.preventDefault();
        if (!passwordMatch) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        try {
            const res = await axios.post(`${baseUrl}/member/join`, {
                member_id,
                password
            })
            alert(res.data);
            window.location.href = '/login';
        } catch (error) {
            console.error(error.response.data)
            alert(error.response.data);
        }
    }

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        if (password === e.target.value) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    }

    return (
        <div className={style.join_main_wrapper}>
            <div className={style.join_main}>
                <div className={style.logo_wrapper}>
                    <img src='/CI_심볼마크.png' alt='logo' />
                </div>
                <div className={style.title_wrapper}>
                    {/* <img src='/회원가입.svg' alt='Join' /> */}
                    <h1>회원가입</h1>
                </div>
                <div className={style.input_wrapper}>
                    <div className={style.input_box}>
                        <h3>아이디</h3>
                        <input type='text' placeholder='아이디를 입력해주세요.' value={member_id} onChange={(e) => setMember_id(e.target.value)} />
                    </div>
                    <div className={style.input_box}>
                        <h3>비밀번호</h3>
                        <input type='password' placeholder='비밀번호를 입력해주세요.' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className={style.input_box}>
                        <h3>비밀번호 확인</h3>
                        {!passwordMatch && <p style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</p>}
                        <input type='password' placeholder='비밀번호를 한번 더 입력해주세요.' value={confirmPassword} onChange={handleConfirmPassword} />
                    </div>
                </div>
                <div className={style.submit_btn_box}>
                    <div className={style.submit_btx} onClick={handdleSubmit}>
                        회원가입
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Join