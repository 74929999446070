import React, { useEffect, useRef, useState } from 'react'
import style from '../SCSS/pages/TalentInput.module.scss'
import LeftBar from '../components/LeftBar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const TalentInput = () => {

    const modelUrl = process.env.REACT_APP_MODEL_URL;
    const model_rag_Url = process.env.REACT_APP_MODEL_RAG_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [companyList, setCompanyList] = useState([]);
    const [filteredCompanyList, setFilteredCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null); // 선택된 회사 이름 저장

    const getCompanyList = async (e) => {
        try {
            const res = await axios.get(`${baseUrl}/company/companies`)
            setCompanyList(res.data)
            setFilteredCompanyList(res.data); // 초기값 설정
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getCompanyList();
    }, [])

    const handleCompanyFilter = (e) => {
        setSelectedCompany(e.target.value);
        const { value } = e.target;
        // 입력값과 일치하는 회사만 필터링
        const filtered = companyList.filter(company =>
            company.company_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCompanyList(filtered);
    };




    const [userText, setUserText] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModal_01Open, setIsModal_01Open] = useState(false);

    const modalRef = useRef();
    const modal_01Ref = useRef();

    const openModal = () => {
        setIsModalOpen(true);
    }

    const openModal_01 = () => {
        setIsModal_01Open(true);
    }


    const closeModal = () => {
        setIsModalOpen(false);
        setIsModal_01Open(false);
    }

    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedCompanyTalent, setSelectedCompanyTalent] = useState('');
    const [talentArray, setTalentArray] = useState([]);

    const onSelectCompany = (name, talent) => {
        setSelectedCompanyName(name);
        setSelectedCompanyTalent(talent);
        setTalentArray(talent.split('\n'));
        setIsModalOpen(false);
    };


    const handleClickOutside = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            closeModal();
        }

        if (isModal_01Open && modal_01Ref.current && !modal_01Ref.current.contains(e.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen || isModal_01Open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen, isModal_01Open]);

    const CompnayComponent = ({ company }) => {
        const handleClick = () => {
            onSelectCompany(company.company_name, company.talent);
        };

        return (

            <div className={style.company_list_box} onClick={handleClick}>
                <div className={style.company_name}>
                    {company.company_name}
                </div>
                <div className={style.company_info}>
                    {/* {company.talent} */}
                    <p dangerouslySetInnerHTML={{ __html: company.talent.replace(/\n/g, '<br/>') }} />
                </div>
                <div className={style.company_img}>
                    {
                        company.logo == 'none' ?
                            // <img src='http://yes114.biz/ready.jpg' alt='no_img' /> 
                            <img src='./ready_img.svg' alt='no_img' />
                            :
                            <img src={company.logo} alt='company_img' />

                    }
                </div>
            </div>

        )
    }

    const [isLoading, setIsLoading] = useState(false);

    const [responseData, setResponseData] = useState('');
    const navigate = useNavigate();


    const handleSubmit = async () => {
        // 각 텍스트 영역의 길이를 가져옵니다.
        const userTextLength = userText.length;

        // 글자 수가 300 미만이거나 1000 초과인 경우 경고 메시지를 표시하고 제출을 막습니다.
        if (userTextLength < 300 || userTextLength > 1000) {
            window.alert("각 항목의 글자 수는 300자 이상 1000자 이하여야 합니다.");
            return;
        }

        if (!selectedCompanyName || talentArray.includes('회사를 찾을 수 없습니다')) {
            window.alert("회사를 선택하지 않았거나 유효한 인재상이 아닙니다.");
            return;
        }


        const confirmed = window.confirm("정말 제출하시겠습니까?");
        if (confirmed) {


            navigate('/talentfeedback/prev', {
                state: {
                    userText,
                    selectedCompanyTalent,
                    selectedCompanyName
                }
            })
        }
    }


    const [aiCompanyName, setAiCompanyName] = useState('');
    const [aiTalentResponse, setAiTalentResponse] = useState('');
    const [companyUrl, setCompanyUrl] = useState('');

    const getCompanyTalent = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${model_rag_Url}/get_talent`, {
                item: aiCompanyName
            })

            if (response.data == '회사를 찾을 수 없습니다') {

                setTalentArray([response.data]);
                setSelectedCompanyName(aiCompanyName)
                setIsModal_01Open(false);
                setIsLoading(false);
            } else {
                const isCsv = response.data.iscsv
                const talentData = response.data.talent.replace("[인재상]", "").trim();

                if (response.data.iscsv != 'true') {
                    try {
                        const res = await axios.post(`${baseUrl}/company/insert`, {
                            company_name: aiCompanyName,
                            talent: talentData
                        })
                    } catch (error) {

                    }
                }


                setSelectedCompanyTalent(talentData);
                setCompanyUrl(response.data.url)
                setSelectedCompanyName(aiCompanyName)
                setTalentArray(talentData.split('\n'));
                setIsModal_01Open(false);
                setIsLoading(false);



            }

        } catch (error) {

        }
    }

    // 회사 주소로 이동하는 함수
    const handleCompanyUrlClick = () => {
        window.open(companyUrl, '_blank');
    }

    // 클래스명 동적 할당 함수
    const getCharCountClassName = (length) => {
        return length < 300 || length > 1000 ? style.warning : '';
    }



    return (
        <div className={style.Main}>

            <LeftBar />
            <div className={style.right_wrapper}>
                <div className={style.ex_content_wrapper}>
                    <div className={style.ex_content_box}>
                        <div className={style.talent_search_wrapper}>
                            <div className={style.talent_search_box}>
                                <h2>
                                    회사 검색
                                </h2>
                                <div className={style.search_btn} onClick={openModal}>
                                    <img src='/search_btn.svg' alt='Search' />
                                </div>
                                <div className={style.search_ai_robot_img} onClick={openModal_01}>
                                    <img src="./ai_search_btn.svg" alt="robot" />
                                </div>

                                {/* <div className={style.search_ai_robot_img}>
                                    <img src="./ai_robot_02.svg" alt="robot" />
                                </div>
                                <div className={style.search_btn_plus} onClick={openModal_01}>
                                    AI 인재상 검색
                                </div> */}

                                {isModal_01Open &&
                                    <div className={style.modal_01}>
                                        <div className={style.modal_box} ref={modal_01Ref}>
                                            <h2>AI 인재상 검색</h2>
                                            <div className={style.search_input_box}>
                                                <input type='text' placeholder='회사명을 입력해주세요.' value={aiCompanyName} onChange={(e) => setAiCompanyName(e.target.value)} />
                                            </div>

                                            <div className={style.ai_talent_search_btn} onClick={getCompanyTalent}>
                                                검색하기
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>

                            {isModalOpen &&
                                <div className={style.modal}>
                                    <div className={style.modal_box} ref={modalRef}>
                                        <div className={style.search_input_box}>
                                            <input type='text' placeholder='회사명을 입력해주세요.' value={selectedCompany} onChange={handleCompanyFilter} />

                                        </div>
                                        <hr />
                                        <div className={style.company_list_warpper}>
                                            {filteredCompanyList &&
                                                filteredCompanyList.map((company) => {
                                                    return (
                                                        <CompnayComponent key={company.company_name} company={company} />
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>

                            }


                            <div className={style.company_btn_box}>
                                {selectedCompanyName}
                            </div>
                            <div className={style.company_talent_box}>
                                {talentArray.map((talent, index) => (
                                    <div key={index}>{talent}</div>
                                ))}
                            </div>
                            {companyUrl &&
                                <div className={style.company_url_box} >
                                    <span onClick={handleCompanyUrlClick}>주소이동</span>
                                </div>
                            }
                        </div>
                        <div className={style.user_text_wrapper}>

                            <div className={style.user_text_box}>
                                <h2>
                                    수정할 문장을 입력 해 주세요.
                                </h2>
                                <textarea placeholder='수정할 문장을 입력해주세요.' value={userText} onChange={(e) => setUserText(e.target.value)}></textarea>
                                <div className={`${style.charCount} ${getCharCountClassName(userText.length)}`}>
                                    {userText.length}/1000
                                </div>
                            </div>


                        </div>
                        <div className={style.submit_btn_wrapper}>
                            <div className={style.submit_btn_box} onClick={handleSubmit}>
                                제출하기
                            </div>
                        </div>

                        {isLoading &&
                            <div className={style.response_modal}>
                                <div className={style.response_modal_box}>
                                    <div className={style.loading_wrapper}>
                                        {/* <div className={style.loading_box}></div> */}
                                        <img src="./CI_심볼마크.png" alt="" />
                                    </div>
                                    <h2>
                                        인재상을 검색 중입니다.
                                    </h2>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TalentInput