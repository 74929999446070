import React, { useEffect, useState } from 'react'
import style from '../SCSS/pages/TalentFeedback.module.scss'
import LeftBar from '../components/LeftBar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { diffChars } from 'diff';
import { useSelector } from 'react-redux';

const TalentFeedback = () => {

    const modelUrl = process.env.REACT_APP_MODEL_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { _id } = useParams(); // id 값 가져오기

    const [gptAnswer, setGptAnswer] = useState('')
    const [userText, setUserText] = useState('')
    const [talent, setTalent] = useState('')
    const [companyName, setCompanyName] = useState('')

    const getGptAnswer = async () => {
        try {
            const res = await axios.post(`${baseUrl}/talent_history/getAnswer`, {
                id: _id,
            })
            setGptAnswer(res.data[0].gpt_answer)
            setUserText(res.data[0].user_text)
            setTalent(res.data[1].talent)
            setCompanyName(res.data[1].company_name)
            setHighlightedText(highlightDiff(res.data[0].user_text, res.data[0].gpt_answer))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getGptAnswer();
    }, [_id])

    const [highlightedText, setHighlightedText] = useState('');

    const highlightDiff = (text1, text2) => {
        const diff = diffChars(text1, text2);
        let highlightedText = '';

        diff.forEach((part) => {
            if (part.added) {
                // const value = part.value.replace(/\n/g, ''); // 개행 문자 제거
                highlightedText += `<span style="background:#aff7af;">${part.value}</span>`;
            } else if (!part.removed) {
                highlightedText += part.value;
            }
        });

        return highlightedText;
    };

    
    const [isDark, setIsDark] = useState(window.localStorage.getItem('theme'));
    const theme = useSelector((state) => state.theme.theme);
    useEffect(() => {
        if (theme == 'light') {
            setIsDark(false);
        } else {
            setIsDark(true);
        }
    }, [theme])

    return (
        <div className={style.Main}>
            <LeftBar />
            <div className={style.right_wrapper}>
                <div className={style.ex_content_wrapper}>
                    <div className={style.ex_content_box}>
                        <div className={style.gpt_answer_wrapper}>
                            <div className={style.category_btn_wrapper}>
                                <div className={style.category_btn_selected}>
                                    {companyName}
                                </div>
                            </div>
                            <hr />


                            <div className={style.gpt_answer_box}>
                                <div className={style.gpt_editing}>
                                    <div className={style.gpt_editing_title}>
                                        <h3>인재상</h3>
                                        <p dangerouslySetInnerHTML={{ __html: talent.replace(/\n/g, '<br/>') }} />
                                    </div>
                                    <div className={style.gpt_editing_content}>
                                        <h3>
                                            원본
                                        </h3>
                                        <div className={style.not_edited_box}>
                                            <div className={style.content_box} dangerouslySetInnerHTML={{ __html: userText.replace(/\n/g, '<br/>') }} />
                                        </div>
                                        <h3>
                                            수정본
                                        </h3>
                                        <div className={style.edited_box}>
                                            {isDark ?
                                                <img src="/ai_robot_white.svg" alt="AI Robot" />
                                                :
                                                <img src="/ai_robot.svg" alt="AI Robot" />
                                            }
                                            {/* <div className={style.edited_content} dangerouslySetInnerHTML={{ __html: gptAnswer.replace(/\n/g, '<br/>') }} /> */}
                                            <div className={style.edited_content} dangerouslySetInnerHTML={{ __html: highlightedText.replace(/\n/g, '<br/>') }} />
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TalentFeedback