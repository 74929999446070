import { Route, Routes, useLocation } from 'react-router-dom';
import Main from './pages/Main';
import './SCSS/base/reset.scss'
import './SCSS/base/font.scss'
import Join from './pages/Join';
import Login from './pages/Login';
import KakaoLogin from './components/KakaoLogin';
import GoogleLogin from './components/GoogleLogin';
import EssayInput from './pages/EssayInput';
import EssayFeedback from './pages/EssayFeedback';
import TalentInput from './pages/TalentInput';
import TalentFeedback from './pages/TalentFeedback';
import TopBanner from './components/TopBanner';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toggleDarkMode } from './store/reducers/themeSlice';
import { getHistoryFirst, getHistoryTalent } from './store/reducers/historySlice';
import TalentFeedback_Prev from './pages/TalentFeedback_Prev'

function App() {
  // const [isDarkMode, setIsDarkMode] = useState(false);
  const location = useLocation();

  const hideTopBannerPath = ['/login', '/join'];

  // 테스트

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();

  const getOrionHisroty = async () => {
    try {
      const response = await axios.post(`${baseUrl}/essay_history/getAllAnswer`, {
        member_id: sessionStorage.getItem('member_id')
      });
      dispatch(getHistoryFirst(response.data.history));
      dispatch(getHistoryTalent(response.data.history_talent));
    } catch (error) {
      console.error("Error getting data:", error);
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('member_id')) {
      getOrionHisroty();
    }
  }, [])

  // const initialTheme = window.localStorage.getItem('theme') || 'light';
  // const [theme, setTheme] = useState(initialTheme);

  // // 컴포넌트 마운트 시 body의 data-theme 설정
  // useEffect(() => {
  //   document.body.setAttribute('data-theme', theme);
  // }, []);

  // const toggleDarkMode = () => {
  //   const newTheme = theme === 'light' ? 'dark' : 'light';
  //   setTheme(newTheme);
  //   window.localStorage.setItem('theme', newTheme);
  //   document.body.setAttribute('data-theme', newTheme);
  // };

  const theme = useSelector((state) => state.theme.theme);

  const handleToggleDarkMode = () => {
    dispatch(toggleDarkMode());
  };


  // 모바일 ui 관련
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // resize
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  })




  return (
    <div className='theme' data-theme={theme}>
      {!hideTopBannerPath.includes(location.pathname) && (
        <TopBanner handleToggleDarkMode={handleToggleDarkMode} theme={theme} />
      )}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/join" element={<Join />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth_kakao" element={<KakaoLogin />} />
        <Route path="/auth_google" element={<GoogleLogin />} />
        <Route path="/essayinput" element={<EssayInput />} />
        <Route path="/essayfeedback/:_id" element={<EssayFeedback />} />
        <Route path="/talentinput" element={<TalentInput />} />
        <Route path="/talentfeedback/:_id" element={<TalentFeedback />} />
        <Route path="/talentfeedback/prev" element={<TalentFeedback_Prev />} />
      </Routes>
    </div>
  );
}
export default App;
