import React, { useState } from 'react'
import style from '../SCSS/pages/Login.module.scss'
import axios from 'axios';

const Login = () => {

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const REST_API_KEY = process.env.REACT_APP_KAKAO_API_KEY;
    const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const [member_id, setMember_id] = useState('');
    const [password, setPassword] = useState('');

    const handdleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await axios.post(`${baseUrl}/member/login`, {
                member_id,
                password
            })
            // alert(res.data);
            sessionStorage.setItem('member_id', member_id);
            window.location.href = '/';
        } catch (error) {
            console.error(error.response.data)
            alert(error.response.data);
        }
    }

    // 엔터 키를 눌렀을 때 form을 submit하도록 이벤트 핸들러 함수 추가
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handdleSubmit(e);
        }
    }

    const REDIRECT_URI = `${baseUrl}/kakao/oauth`
    const link = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    const kakaoLogin = () => {
        window.location.href = link;
    }

    const redirect_uri_google = `${baseUrl}/google/oauth`;

    const googleLogin = () => {
        window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_id}&redirect_uri=${redirect_uri_google}&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email`;
    }

    return (
        <div className={style.login_main_wrapper}>
            <div className={style.login_main}>
                <div className={style.logo_wrapper}>
                    <img src='/CI_심볼마크.png' alt='logo' />
                </div>
                <div className={style.title_wrapper}>
                    {/* <img src='/로그인.svg' alt='Join' /> */}
                    <h1>로그인</h1>
                </div>
                <div className={style.input_wrapper}>
                    <div className={style.input_box}>
                        <h3>아이디</h3>
                        {/* onKeyDown 이벤트 핸들러 추가 */}
                        <input type='text' placeholder='아이디를 입력해주세요.' value={member_id} onChange={(e) => setMember_id(e.target.value)} onKeyDown={handleKeyDown} />
                    </div>
                    <div className={style.input_box}>
                        <h3>비밀번호</h3>
                        {/* onKeyDown 이벤트 핸들러 추가 */}
                        <input type='password' placeholder='비밀번호를 입력해주세요.' value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
                    </div>
                </div>
                <div className={style.submit_btn_box}>
                    {/* 엔터 키 눌렀을 때 form을 submit하도록 변경 */}
                    <form onSubmit={handdleSubmit}>
                        <div className={style.submit_btx} onClick={handdleSubmit}>
                            로그인
                        </div>
                    </form>
                </div>
                <div className={style.social_login_wrapper}>
                    <div className={style.kakao_login_box} onClick={kakaoLogin}>
                        <img src='kakao_loginsvg.svg' alt='kakao_login' />
                    </div>
                    {/* <h2>
                        or
                    </h2> */}
                    <div className={style.google_login_box} onClick={googleLogin}>
                        <img src='web_light_sq_SI.svg' alt='google_login' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
