import React, { useEffect, useState } from 'react'
import style from '../SCSS/pages/TalentFeedback.module.scss'
import LeftBar from '../components/LeftBar';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { diffChars } from 'diff';

const TalentFeedback_Prev = () => {

    const location = useLocation();
    const { userText, selectedCompanyTalent, selectedCompanyName } = location.state || {};

    const navigate = useNavigate();

    const modelUrl = process.env.REACT_APP_MODEL_URL;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [responseData, setResponseData] = useState('');

    // 난수생성
    const generateRandomItemName = () => {
        return Math.random().toString(36).substring(2, 15); // 랜덤한 문자열 생성
    }

    const handleSubmit = async () => {
        try {
            const eventSource = new EventSource(`${modelUrl}/sse_01`, { withCredentials: true });
            // const eventSource = new EventSource(`${modelUrl}/sse`);
            eventSource.onmessage = (event) => {
                // const data = event.data;
                // console.log('성공');

                const data = JSON.parse(event.data);
                // 데이터를 화면에 렌더링하는 로직 작성
                setResponseData(data.data);
                setHighlightedText(highlightDiff(userText, data.data))
                // console.log(data);
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
            };

            const response = await axios.post(`${modelUrl}/talent`, {
                req_id: generateRandomItemName(),
                talent: selectedCompanyTalent,
                text: userText,
            });

            try {
                const res = await axios.post(`${baseUrl}/talent_history/talent`, {
                    member_id: sessionStorage.getItem('member_id'),
                    user_text: userText,
                    gpt_answer: response.data,
                    company_name: selectedCompanyName
                })
                sessionStorage.setItem('gpt_answer_id', res.data);
                navigate(`/talentfeedback/${res.data}`)
                // window.location.href = `/talentAnswer/${res.data}`
            } catch (error) {

            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleSubmit();
    }, [])


    const [highlightedText, setHighlightedText] = useState('');

    const highlightDiff = (text1, text2) => {
        const diff = diffChars(text1, text2);
        let highlightedText = '';

        diff.forEach((part) => {
            if (part.added) {
                // const value = part.value.replace(/\n/g, ''); // 개행 문자 제거
                highlightedText += `<span style="background:#aff7af;">${part.value}</span>`;
            } else if (!part.removed) {
                highlightedText += part.value;
            }
        });

        return highlightedText;
    };


    return (
        <div className={style.Main}>
            <LeftBar />
            <div className={style.right_wrapper}>
                <div className={style.ex_content_wrapper}>
                    <div className={style.ex_content_box}>
                        <div className={style.gpt_answer_wrapper}>
                            <div className={style.category_btn_wrapper}>
                                <div className={style.category_btn_selected}>
                                    {selectedCompanyName}
                                </div>
                            </div>
                            <hr />


                            <div className={style.gpt_answer_box}>
                                <div className={style.gpt_editing}>
                                    <div className={style.gpt_editing_title}>
                                        <h3>인재상</h3>
                                        <p dangerouslySetInnerHTML={{ __html: selectedCompanyTalent.replace(/\n/g, '<br/>') }} />
                                    </div>
                                    <div className={style.gpt_editing_content}>
                                        <h3>
                                            원본
                                        </h3>
                                        <div className={style.not_edited_box}>
                                            <div className={style.content_box} dangerouslySetInnerHTML={{ __html: userText.replace(/\n/g, '<br/>') }} />
                                        </div>
                                        <h3>
                                            수정본
                                        </h3>
                                        <div className={style.edited_box}>
                                            <img src="/ai_robot.svg" alt="AI Robot" />
                                            {/* {highlightedText} */}
                                            <div className={style.edited_content} dangerouslySetInnerHTML={{ __html: highlightedText.replace(/\n/g, '<br/>') }} />
                                            {/* <div className={style.edited_content} dangerouslySetInnerHTML={{ __html: gptAnswer.replace(/\n/g, '<br/>') }} /> */}
                                            {/* <div className={style.edited_content} dangerouslySetInnerHTML={{ __html: highlightedText.replace(/\n/g, '<br/>') }} /> */}
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TalentFeedback_Prev