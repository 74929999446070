import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './reducers/themeSlice';
import historyReducer from './reducers/historySlice'

const store = configureStore({
  reducer: {
    theme: themeReducer,
    history: historyReducer
  },
});

export default store;