import React, { useEffect, useRef, useState } from 'react'
import style from '../SCSS/components/LeftBar.module.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const LeftBar = () => {
    const historyInfoAll = useSelector((state) => state.history);

    const [isDark, setIsDark] = useState(window.localStorage.getItem('theme'));
    const theme = useSelector((state) => state.theme.theme);
    useEffect(() => {
        if (theme == 'light') {
            setIsDark(false);
        } else {
            setIsDark(true);
        }
    }, [theme])


    useEffect(() => {

        // 창의 크기가 변경될 때마다 리스너 추가
        window.addEventListener('resize', handleWindowResize);

        // 컴포넌트가 언마운트될 때 리스너 제거
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
    }, [])

    // 창의 크기가 변경될 때마다 호출되는 함수
    const handleWindowResize = () => {
        // 창의 너비가 1370px 이하인 경우 folded 값을 true로 설정
        if (window.innerWidth <= 1370) {
            setFolded(true);
        } else {
            setFolded(false);
        }
    };

    const [orionResponses, setOrionResponses] = useState([]);
    const [orionResponses_talent, setOrionResponses_talent] = useState([]);

    useEffect(() => {
        setOrionResponses([...historyInfoAll.history_first]);
        setOrionResponses_talent([...historyInfoAll.history_talent]);
    }, [historyInfoAll]);

    const OrionHistoryCompo = ({ props }) => {
        return (
            <Link to={`/essayfeedback/${props._id}`}>
                <div className={style.history_block} >
                    <h4>
                        {new Date(props.createAt).toLocaleString()}
                    </h4>
                </div>
            </Link>
        )
    }

    const OrionHistoryCompo_talent = ({ props }) => {


        return (
            <Link to={`/talentfeedback/${props._id}`} >
                <div className={style.history_block} >
                    <h4>
                        {new Date(props.createAt).toLocaleString()}
                    </h4>
                </div>
            </Link>
        )
    }

    const [folded, setFolded] = useState(window.innerWidth <= 1370);

    // foldBox 클릭 핸들러
    const foldBoxClickHandler = () => {
        setFolded(!folded); // foldBox 상태를 토글
    };


    return (
        <>
            <div className={`${style.left_bar} ${folded == true ? style.folded : ''}`}>
                <div className={`${style.logo_wrapper} ${folded == true ? style.folded : ''}`}>
                    <div className={`${style.fold_wrapper} ${folded == true ? style.folded : ''}`} onClick={foldBoxClickHandler}>
                        {/* <div className={style.fold_box} /> */}
                        {isDark ?
                            <img src='/hamburger_dark.svg' alt='hamburger' />
                            :
                            <img src='/hamburger.svg' alt='hamburger' />
                        }
                    </div>
                    <Link to='/'>
                        <div className={style.title_box}>
                            {isDark ?
                                <img src='/CI_LOGO_WHITE.svg' alt='logo' />
                                :
                                <img src='/CI_LOGO_BLACK.svg' alt='logo' />
                            }
                        </div>
                    </Link>
                </div>
                <div className={style.history_wrapper}>
                    <div className={style.history_job}>
                        <h2>
                            자소서 첨삭
                        </h2>

                        {orionResponses && orionResponses.length > 0 && orionResponses
                            .sort((a, b) => new Date(b.createAt) - new Date(a.createAt))
                            .map((item, index) => (
                                <OrionHistoryCompo props={item} key={index} />
                            ))}
                    </div>
                    <div className={style.history_talent}>
                        <h2>
                            인재상 분석
                        </h2>
                        {orionResponses_talent && orionResponses_talent.length > 0 && orionResponses_talent
                            .sort((a, b) => new Date(b.createAt) - new Date(a.createAt))
                            .map((item, index) => (
                                <OrionHistoryCompo_talent props={item} key={index} />
                            ))}
                    </div>
                </div>
            </div>
            {/* <div className={`${style.fold_wrapper} ${folded == true ? style.folded : ''}`} onClick={foldBoxClickHandler}>
                <div className={style.fold_box} />
            </div> */}
        </>
    )
}

export default LeftBar